<template>
    <div class="app-logo-edition">
        <nuxt-link to="/">
            <koala-logo v-if="edition === 'KANK'" />
            <springmuis-logo v-if="edition === 'KANS'" />
            <wallabie-logo v-if="edition === 'KANWB'" />
            <wallaroe-logo v-if="edition === 'KANWR'" />
            <wombat-logo v-if="edition === 'KANWOM'" />
            <!-- TODO KANQ & KANNUM -->
            <quokka-logo v-if="edition === 'KANQ'" />
            <numbat-logo v-if="edition === 'KANNUM'" />
            <!-- add vwo logo's -->
        </nuxt-link>
    </div>
</template>

<script lang="ts">
import get from 'lodash/get';
import KoalaLogo from '~/assets/img/koala.svg';
import SpringmuisLogo from '~/assets/img/springmuis.svg';
import WallabieLogo from '~/assets/img/wallabie.svg';
import WallaroeLogo from '~/assets/img/wallaroe.svg';
import WombatLogo from '~/assets/img/wombat.svg';
import QuokkaLogo from '~/assets/img/quokka.svg';
import NumbatLogo from '~/assets/img/numbat.svg';

export default {
    components: {
        KoalaLogo,
        SpringmuisLogo,
        WallabieLogo,
        WallaroeLogo,
        WombatLogo,
        QuokkaLogo,
        NumbatLogo,
    },
    setup() {
        const user = useUser();
        return { user }
    },
    computed: {
        edition() {
            // return 'koala'
            return get(this.user, 'edition', false);
        },
    },
};
</script>
