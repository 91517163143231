<template>
    <div class="app-logo">
        <v-row v-if="edition === 'kangoeroe'" justify="center">
            <v-col cols="8" md="4">
                <img
                    src="~/assets/img/kangoeroe-logo-2.png"
                    alt="kangoeroe logo"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
import get from 'lodash/get';
import KangoeroeLogoWhite from '~/assets/img/kangoeroe-logo-white.svg?inline';

export default {
    setup() {
        const user = useUser();
        const runtimeConfig = useRuntimeConfig();
        return { user, runtimeConfig }
    },
    data() {
        return {
            logo: {
                base: KangoeroeLogoWhite,
            },
        };
    },
    computed: {
        edition() {
            return get(this.user, 'edition', this.runtimeConfig?.public?.parentEdition);
        },
    },
};
</script>
<style scoped>
.app-logo >>> img {
    width: 100%;
    height: auto;
}
</style>
