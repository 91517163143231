<template>
    <v-app dark class="app-layout__default pattern fill-height">
        <v-container
            :class="`bg-${edition}`"
            :fluid="edition !== 'kangoeroe' && $vuetify.display.lgAndDown"
            class="app-page"
        >
            <v-row justify="center" class="my-3">
                <v-col cols="auto">
                    <!-- prevent server/client mismatch -->
                    <client-only>
                        <app-logo class="app-page__logo" />
                    </client-only>
                </v-col>
            </v-row>

            <v-row justify="center" class="my-3">
                <v-col cols="12">
                    <div
                        class="bg-white elevation-6 rounded-xl pa-6 mx-lg-6 app-layout__content"
                    >
                        <v-row justify="center">
                            <v-col cols="auto">
                                <!-- prevent server/client mismatch -->
                                <client-only>
                                    <app-logo-edition
                                        class="app-page__logo-edition"
                                    />
                                </client-only>
                            </v-col>
                        </v-row>
                        <!-- page content -->
                        <slot v-if="phase.phase === 4 || phase.phase === 2" />
                        <v-row v-else justify="center">
                            <v-col cols="auto" md="8" class="text-center">
                                {{ phase.description }}
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <img
                                    src="~/assets/img/kangoeroe-logo.png"
                                    alt="kangoeroe logo"
                                />
                            </v-col>
                        </v-row>
                        <div
                            v-if="useRoute().name === 'question-id'"
                            class="app-layout__back-link pa-6"
                        >
                            <nuxt-link to="/">
                                <v-icon x-large :color="edition">
                                    mdi-arrow-left-bold-circle
                                </v-icon>
                                <span
                                    v-if="$vuetify.display.mdAndUp"
                                    :class="`text-${edition}`"
                                >
                                    Naar overzicht
                                </span>
                            </nuxt-link>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script lang="ts">
import get from 'lodash/get';

export default {
    setup() {
        const route = useRoute();
        const user = useUser();
        const runtimeConfig = useRuntimeConfig();
        const auth = useAuth();
        return { route, user, runtimeConfig, auth, useFetch, submitAnswers }
    },
    computed: {
        edition() {
            return get(this.user, 'edition', 'kangoeroe');
        },
        user() {
            return get(this.user, 'firstName', '');
        },
        phase() {
            return this.$phase;
        },
    },
    async mounted() {
        const {status, signOut} = this.auth;
        const route = this.route;
        const query = route.query.date ? `?date=${route.query.date}` : '';

        this.useFetch(`${window.location.origin}/api/dates/kangoeroe/app${query}`)
            .then(({data}) => {
                this.$phase.set(data.value);
            });

        if (this.user?.sessionExpired) {
            if (this.user?.phase !== 4) {
                localStorage.clear();
                signOut({redirect: false});
                navigateTo({
                    path: '/login/auth',
                })
            } else {
                const data = await this.submitAnswers();

                navigateTo({
                    name: 'results',
                    query: {
                        sessionExpired: true,
                        ...data
                    },
                });
            }
        }
    },
    methods: {
        date(val: number) {
            return new Date(val).toLocaleDateString('nl-BE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        },
    },
};
</script>

<style>
.pattern {
    background-image: url('~/assets/img/pattern.png') !important;
}
.v-application a {
    color: inherit;
    text-decoration: none;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-active {
    opacity: 0;
}
.app-page {
    min-height: 100vh;
}
.app-page__logo-edition {
    margin-top: -80px;
}
.app-page__logout {
    text-decoration: underline;
    text-decoration-color: white;
}

.app-layout__content {
    min-height: 75vh;
    position: relative;
}
.app-layout__back-link {
    position: absolute;
    left: 0;
    top: 0;
}
</style>
